import Column from "@/Components/Grid/Column"
import Container from "@/Components/Grid/Container"
import Row from "@/Components/Grid/Row"
import FrontLayout from "@/Layouts/FrontLayout"
import { Head } from "@inertiajs/react"

export default function ErrorPage({ status }: { status: number }) {
    const title = {
        503: '503: Serwis niedostępny',
        500: '500: Błąd serwera',
        404: '404: Nie odnaleziono strony',
        403: '403: Dostęp zabroniony',
    }[status]

    return (
        <>
            <Head title="Osiedle Kusocińskiego">
                <meta name='description' content='Osiedle Kusocińskiego' />
            </Head>
            <FrontLayout> 
                <Container>
                    <Row>
                        <Column>
                            <div className="h-screen text-center flex items-center justify-center">
                                <h1 className="text-h1 leading-h1 font-extrabold text-primary uppercase">{title}</h1>
                            </div>
                        </Column>
                    </Row>
                </Container>
            </FrontLayout>
        </>
    )
}